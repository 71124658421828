<template>
  <div class="deposit-home-wrap">
    <div class="card-box-1 ub ub-h-c">
      <div class="user-info ub ub-btw padding15">
        <div class="info-box ub ub-h-c">
          <div class="avatar-box margin-right12">
            <div class="img-box"  v-if="userInfo.avatar_address">
              <img :src="userInfo.avatar_address" alt="">
            </div>
            <div class="img-box"  v-else>
              <img src="../../../assets/images/profile/defaultAvatar.svg" alt="">
            </div>
          </div>
          <div v-if="token" @click="goInformation">
            <p class="font20 user-account-color">{{userInfo.email || userInfo.mobile}}</p>
            <p class="font14 user-tip-color">UID: {{ userInfo.user_id }}</p>
          </div>
          <div v-else @click="goLogin">
            <p class="font20 user-account-color">Log in / Sign up</p>
            <p class="font14 user-tip-color">Log in for more</p>
          </div>
        </div>
        <div class="ub ub-cend" v-if="token">
          <div class="ub ub-cen">
            <img src="@/assets/images/profile/rightArrowW.svg" alt="" srcset="">
          </div>
        </div>
      </div>
    </div>
    <div class="card-box-2 ub ub-cen">
      <div class="card-box-2-main">
        <div  class="content-item  ub ub-btw padding15" @click="goLanguage">
          <div class="main-text-1 font16 ub ub-h-c">
            <div class="ub ub-cen margin-right8">
              <img src="@/assets/images/profile/language.svg" alt="" srcset="">
            </div>
            <p>{{ $t('profile.language') }}</p>
          </div>
          <div class="ub ub-cend">
            <div style="color:#BAB7BF">
              {{ localeLabel }}
            </div>
            <div class="ub ub-cen">
              <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
            </div>
          </div>
        </div>
        <div class="content-item ub ub-btw padding15">
          <div class="main-text-1 font16 ub ub-h-c">
            <div class="ub ub-cen margin-right8">
              <img src="@/assets/images/profile/helpCenter.svg" alt="" srcset="">
            </div>
            <p>{{ $t('profile.helpCenter') }}</p>
          </div>
          <div class="ub ub-cend">
            <div class="ub ub-cen">
              <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
            </div>
          </div>
        </div>
        <div class="content-item ub ub-btw padding15">
          <div class="main-text-1 font16 ub ub-h-c">
            <div class="ub ub-cen margin-right8">
              <img src="@/assets/images/profile/customerService.svg" alt="" srcset="">
            </div>
            <p>{{ $t('profile.customerService') }}</p>
          </div>
          <div class="ub ub-cend">
            <div class="ub ub-cen">
              <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
            </div>
          </div>
        </div>
        <div class="content-item ub ub-btw padding15" v-if="token" @click="goSecurity">
          <div class="main-text-1 font16 ub ub-h-c">
            <div class="ub ub-cen margin-right8">
              <img src="@/assets/images/profile/security.svg" alt="" srcset="">
            </div>
            <p>{{ $t('profile.security') }}</p>
          </div>
          <div class="ub ub-cend">
            <div class="ub ub-cen">
              <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
            </div>
          </div>
        </div>
        <div class="content-item ub ub-btw padding15" @click="goAbout">
          <div class="main-text-1 font16 ub ub-h-c">
            <div class="ub ub-cen margin-right8">
              <img src="@/assets/images/profile/aboutBewincoin.svg" alt="" srcset="">
            </div>
            <p>{{ $t('profile.aboutBewincoin') }}</p>
          </div>
          <div class="ub ub-cend">
            <div class="ub ub-cen">
              <img src="@/assets/images/profile/rightArrow.svg" alt="" srcset="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { userProfile } from '@/api/profile.js'
import { getToken } from '@/utils/token.js'

import { mapGetters } from 'vuex'
export default {
  name: 'ProfileHome',
  components: {},
  computed: {
    token () {
      return getToken()
    },
    localeLabel () {
      return this.langs.find(f => f.lang_code === this.locale)?.lang || '--'
    },
    ...mapGetters(['locale', 'langs'])
  },
  data () {
    return {
      userInfo: {}
    }
  },
  mounted () {
    if (getToken()) {
      this.userProfileFn()
    }
  },
  methods: {
    goInformation () {
      this.$router.push('/profile/information')
    },
    goSecurity () {
      this.$router.push('/profile/security')
    },
    goLogin () {
      this.$router.push('/register/login')
    },
    goLanguage () {
      this.$router.push('/profile/language')
    },
    goAbout () {
      this.$router.push('/profile/about')
    },
    userProfileFn () {
      userProfile({}).then(res => {
        this.$toast.clear()
        this.userInfo = res.data
        this.$store.dispatch('updateUserInfo', this.userInfo)
      })
    }
  }
}
</script>
<style lang="less">
.deposit-home-wrap {
  height: 100%;
  width: 100%;
  background-color: var(--background-color-2);
  .user-account-color{
    color: #fff;
  }
  .user-tip-color{
    color: rgba(255, 255, 255, 0.70);
  }
  .card-box-1{
    box-sizing: border-box;
    width: 100%;
    height: 480px;
    flex-shrink: 0;
    background-image: url('../../../assets/images/profile/bg.svg');
     // background-size: cover;
     background-position: 0px -80px;
    .user-info{
      width: 100%;
      .avatar-box{
        width: 112px;
        height: 112px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .card-box-2{
    margin-top: -80px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 30px;
    .card-box-2-main{
      box-sizing: border-box;
      background-color: #fff;
      width: 100%;
      border-radius: 16px;
      .content-item{
        box-sizing: border-box;
        height: 120px;
      }
    }
  }
}
</style>
